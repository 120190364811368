import React, { useContext, createRef, useState } from "react";
import { useStateContext } from "../Context/ContextProvider";
import { Link, Navigate } from "react-router-dom";
import axiosClient from "../axios-client";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";



const ForgotPassword = () => {

    const emailRef = createRef()
    const [btnLoading, setBtnLoading] = useState(false)
    // const onSignup = (e) =>{
    //     e.preventDefault()
    //     {<Navigate to="newdealer"/>}
    // }
    const onSubmit = (e) => {
        e.preventDefault()
        setBtnLoading(true)
        const payload = {
            email: emailRef.current.value,
        }
        axiosClient.post('/forgot-password', payload)
            .then(({ data }) => {
                setBtnLoading(false)
                toast.success(data.message, {
                  position: "bottom-right",
                  autoClose: 1500,
                  theme: "colored",
                });
            })
            .catch(err => {
                setBtnLoading(false)
                var firstErrorMessage = '';
                if(err.response.data.errors){
                  var errors = err.response.data.errors
                  const errorMessages = Object.values(errors); // Get an array of all error messages
                  firstErrorMessage = errorMessages.shift(); // Get the first error message
                  console.log('firstErrorMessage=',firstErrorMessage[0])
                  toast.error(firstErrorMessage[0], {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                  });
                } else {
                    // console.log('firstErrorMessage',firstErrorMessage[0])
                    toast.error(firstErrorMessage[0], {
                      position: "bottom-right",
                      autoClose: 1500,
                      theme: "colored",
                    });
                }
            //   console.log(err);
          });
    }



    return (
        <div className="container darkMode">
            <div className="centerForm">
                <div className="authForm box-shadow-1 mb-4">
                    <form onSubmit={onSubmit}>

                        <h4 className="mt-1 text-white">Forgot Password</h4>
                        <p className="text-white">Welcome back, reset your password</p>

                        <div className="form-outline mb-3">
                            <input ref={emailRef} type="text" className="form-control" placeholder="Your Email" aria-label="Username" aria-describedby="addon-wrapping" />
                        </div>

                        <div className="d-grid">
                            <button type="submit" className="btn btn-primary btn-block" onClick={onSubmit} disabled={btnLoading}>
                                {
                                    btnLoading?
                                    (
                                        <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                    ):(
                                        <>Reset Password</>
                                    )
                                }
                            </button>
                        </div>

                    </form>
                </div>
                <div className="text-center">
                    <p className="text-white">
                        <span>Already registered? </span>
                        <Link to="/login" className="ml-2">Login</Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword

