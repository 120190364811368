import React, { useContext, createRef, useState, useEffect } from "react";
import axiosClient from "../axios-client";
import { useStateContext } from "../Context/ContextProvider";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import * as Icon from "react-feather";

import PlacesAutoComplete from '../Components/PlacesAutocomplete';

const accessToken = localStorage.getItem('ACCESS_TOKEN');

const Login = () => {

    let { userId, token } = useParams();

    const location = useLocation();
    const navigate = useNavigate();

    const inviteParam = new URLSearchParams(location.search);

    const [getMessage, setMessage] = useState('')
    const [btnLoading, setBtnLoading] = useState(false)
    const [getEmail, setEmail] = useState("");

    const [password, setPassword] = useState("");
    const [confirm_password, setConfirm_password] = useState("");
    const [name, setName] = useState("");
  
    const [passwordType, setPasswordType] = useState(false);
    const [confirmPasswordType, setConfirmPasswordType] = useState(false)

    const [getInvitationStatus, setInvitationStatus] = useState('pending');

    const [address, setAddress] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");

    const handlePlacesAutocompleteSelect = (latitude, longitude, address) => {
        // set the state of the Signup component with the latLng and address values
        setLatitude(latitude);
        setLongitude(longitude);
        setAddress(address);
    };

    const getInvitation = () => {
        setBtnLoading(true)
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/invitation/' + userId + '/' + token,
        };
        axios(config)
            .then((res) => {
                setBtnLoading(false)
                // setComponentLoading(false)
                console.log('res=', res);
                setMessage(res.data.message)
                setEmail(res.data.email)
                setInvitationStatus(res.data.invitation_status)
            })
            .catch(err => {
                setBtnLoading(false)
                console.log(err.response.data)
                if (err.response.data.errors.length > 0) {
                    var errors = err.response.data.errors
                    const errorMessages = Object.values(errors); // Get an array of all error messages
                    setMessage(errorMessages.shift()); // Get the first error message
                } else {
                    setMessage(err.response.data.message)
                }
            });
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setBtnLoading(true)
        const payload = {
            name: name,
            password: password,
            confirm_password: confirm_password,
            address: address,
            latitude: latitude,
            longitude: longitude,
        }
        axiosClient.put('/invitation/' + userId + '/' + token, payload)
            .then((res) => {
                setBtnLoading(false)
                toast.success(res.data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                // window.location.replace("/");
                navigate("/login")
            })
            .catch(err => {
                setBtnLoading(false)
                var firstErrorMessage = '';
                if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
                    var errors = err.response.data.errors
                    const errorMessages = Object.values(errors); // Get an array of all error messages
                    firstErrorMessage = errorMessages.shift(); // Get the first error message
                    toast.error(firstErrorMessage[0], {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                } else {
                    // console.log('firstErrorMessage',firstErrorMessage[0])
                    toast.error(err.response.data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
                // console.log(err);
            });
    }

    useEffect(() => {

        console.log('process.env.REACT_APP_API_URL=', process.env.REACT_APP_API_URL)

        getInvitation()

        // if(inviteParam.has("invite") && (inviteParam.get("invite") || inviteParam.get("invite") != '')){
        //     if(inviteParam.get("invite") == 'success'){
        //         setMessage('Invitation has been accepted, now you can login')
        //     } else {
        //         setMessage('Something went wrong')
        //     }
        // } else {
        //     navigate("/login");
        // }

    }, []);

    return (
        <div className="container darkMode">
            <div className="centerForm">
                <div className="authForm box-shadow-1 mb-4">

                    {
                        getInvitationStatus != 'pending' ?
                            (
                                <>
                                    <div className="text-center">
                                        <p className="text-white">Invitation has already accepted</p>
                                        <Link to={'/login'} className="btn btn-primary">Login</Link>
                                    </div>
                                </>
                            ) : (
                                <form onSubmit={onSubmit}>

                                    <p className="text-white">Welcome! To get started, please set up your password</p>

                                    <div className="form-outline mb-3">
                                        <input type="text" className="form-control w-100" placeholder="Full Name" aria-label="Username" aria-describedby="addon-wrapping" value={name} onChange={e => setName(e.target.value)} />
                                    </div>

                                    {/* <div className="form-outline mb-3">
                                        <input type="password" className="form-control" placeholder="Password" aria-label="Username" aria-describedby="addon-wrapping" value={password} onChange={e => setPassword(e.target.value)} />
                                    </div>

                                    <div className="form-outline mb-3">
                                        <input type="password" className="form-control" placeholder="Confirm Password" aria-label="Username" aria-describedby="addon-wrapping" value={confirm_password} onChange={e => setConfirm_password(e.target.value)} />
                                    </div> */}

                                    <div className="form-outline mb-3">
                                        <div className="inputIcon">
                                        <input type={passwordType?("text"):("password")} className="form-control" placeholder="Password" aria-label="Username" aria-describedby="addon-wrapping" value={password} onChange={e => setPassword(e.target.value)} />
                                        <div className="inputIcons">
                                            {
                                            passwordType?
                                            (
                                                <span onClick={() => {setPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                                            ):(
                                                <span onClick={() => {setPasswordType(true)}} className="eye"><Icon.Eye /></span>
                                            )
                                            }
                                        </div>
                                        </div>
                                    </div>

                                    <div className="form-outline mb-3">
                                        <div className="inputIcon">
                                        <input type={confirmPasswordType?("text"):("password")} className="form-control" placeholder="Confirm Password" aria-label="Username" aria-describedby="addon-wrapping" value={confirm_password} onChange={e => setConfirm_password(e.target.value)} />
                                        <div className="inputIcons">
                                            {
                                            confirmPasswordType?
                                            (
                                                <span onClick={() => {setConfirmPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                                            ):(
                                                <span onClick={() => {setConfirmPasswordType(true)}} className="eye"><Icon.Eye /></span>
                                            )
                                            }
                                        </div>
                                        </div>
                                    </div>

                                    <div className="form-outline mb-3">

                                        <PlacesAutoComplete onSelect={handlePlacesAutocompleteSelect} />

                                    </div>

                                    <div className="d-grid">
                                        {/* <Link to="/login" className="btn btn-primary btn-block">Login</Link> */}
                                        <button className="btn btn-primary btn-block" disabled={btnLoading}>
                                            {
                                                btnLoading ?
                                                    (
                                                        <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                                    ) : (
                                                        <>Set Your Password</>
                                                    )
                                            }
                                        </button>
                                    </div>

                                </form>
                            )
                    }


                </div>
                <div className="text-center text-white">
                    <p>
                        <span>Not a Member yet? </span>
                        <Link to="/register" className="ml-2">Register</Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Login

