import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import axiosClient from "../axios-client";
import { ToastContainer, toast } from "react-toastify";

const Signup = () => {

    let { userId, token } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    //state to store our code 
    const [code, setCode] = useState("");
    
    const [btnLoading, setBtnLoading] = useState(false)
    const [error, setError] = useState("");
    const [getEmail, setEmail] = useState("");

    const getUserDetailByToken = () => {
        setBtnLoading(true)
        axiosClient.get('/verify/'+token)
            .then((response) => {
                setBtnLoading(false)
                setEmail(response.data.email)
            }).catch(err => {
                setBtnLoading(false)
                var firstErrorMessage = '';
                if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
                    var errors = err.response.data.errors
                    const errorMessages = Object.values(errors); // Get an array of all error messages
                    firstErrorMessage = errorMessages.shift(); // Get the first error message
                    toast.error(firstErrorMessage[0], {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                } else {
                    // console.log('firstErrorMessage',firstErrorMessage[0])
                    toast.error(err.response.data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
                //   console.log(err);
            });

    }

    const handleSubmit = (e) => {
        setBtnLoading(true)
        e.preventDefault();
        const payload = {
            code: code,
            token: token,
        }

        axiosClient.post('/verify', payload)
            .then((response) => {
                setBtnLoading(false)
                console.log('response', response)
                // response.json({ message: 'Request received!', response })
                toast.success(response.data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                // setCode("");
                // setConfirm_password("");
                navigate("/login")
            }).catch(err => {
                setBtnLoading(false)
                var firstErrorMessage = '';
                if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
                    var errors = err.response.data.errors
                    const errorMessages = Object.values(errors); // Get an array of all error messages
                    firstErrorMessage = errorMessages.shift(); // Get the first error message
                    toast.error(firstErrorMessage[0], {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                } else {
                    // console.log('firstErrorMessage',firstErrorMessage[0])
                    toast.error(err.response.data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
                //   console.log(err);
            });

    }

    const handleSubmitResendCode = (e) => {
        setBtnLoading(true)
        e.preventDefault();
        const payload = {
            code: code,
            token: token,
        }

        axiosClient.post('/resend-code', payload)
            .then((response) => {
                setBtnLoading(false)
                console.log('response', response)
                // response.json({ message: 'Request received!', response })
                toast.success(response.data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
            }).catch(err => {
                setBtnLoading(false)
                var firstErrorMessage = '';
                if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
                    var errors = err.response.data.errors
                    const errorMessages = Object.values(errors); // Get an array of all error messages
                    firstErrorMessage = errorMessages.shift(); // Get the first error message
                    toast.error(firstErrorMessage[0], {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                } else {
                    // console.log('firstErrorMessage',firstErrorMessage[0])
                    toast.error(err.response.data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
                //   console.log(err);
            });

    }

    useEffect(() => {
        // check if the password is valid
        getUserDetailByToken()
      }, []);

    return (
        <div>

            <div className="container darkMode">
                <div className="centerForm">
                    <div className="authForm box-shadow-1 mb-4">
                        <form onSubmit={handleSubmit}>

                            {/* <h4 className="mt-1 text-white">Verify</h4> */}
                            <p className="text-white">Enter the code to verify your email, code already sent to your email <span className="text-primary">{getEmail}</span>.</p>

                            <div className="form-outline mb-3">
                                <input type="text" className="form-control" placeholder="Code" aria-label="Code" aria-describedby="addon-wrapping" value={code} onChange={e => setCode(e.target.value)} />
                            </div>

                            <div className="d-grid">
                                <button type="submit" className="btn btn-primary btn-block" disabled={btnLoading}>
                                    {
                                        btnLoading ?
                                            (
                                                <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                            ) : (
                                                <>Verify</>
                                            )
                                    }
                                </button>
                            </div>

                        </form>
                    </div>
                    <div className="text-center">
                        <p className="text-white">
                            <span>Did not receive code in email? </span>
                            <button onClick={handleSubmitResendCode} className="ml-2 btnToLink">Resend</button>
                        </p>
                    </div>
                    <div className="text-center">
                        <p className="text-white">
                            <span>Already registered? </span>
                            <Link to="/login" className="ml-2">Login</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;