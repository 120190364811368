import { FC, useState, createContext, useContext } from 'react'

const ComponentLoading = () => {
  return (
    <>
      <div className="componentLoading bg-white text-center">
        <div className="spinner-border m-1" role="status"></div>
        <div className='mt-1'>
          <span className='text-dark'>Loading ...</span>
        </div>
      </div>
    </>
  )
}

export { ComponentLoading }