import React from 'react'
import MapContainer from './MapContainer';
import LocationSearchInput from './LocationSearchInput'

const MapLocation = () => {

    // return <MapContainer />;
    return <LocationSearchInput />;
    
}

export default MapLocation



