import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axiosClient from '../axios-client';
import { ToastContainer, toast } from 'react-toastify';
import * as Icon from "react-feather";


const UpdatePassword = () => {

  //to check if the employees password meets certain criterias
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

  const [validPass, setValidPass] = useState(false);


  const [old_password, setCurrentPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_new_password, setConfirmPassword] = useState("");
  const [btnLoading, setBtnLoading] = useState(false)

  
  const [oldPasswordType, setOldPasswordType] = useState(false);
  const [newPasswordType, setNewPasswordType] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState(false)

  useEffect(() => {
    // check if the password is valid
    setValidPass(PWD_REGEX.test(old_password));
  }, [old_password]);

  // useEffect(() => {
  //   // check if the password is valid
  //   setValidPass(PWD_REGEX.test(new_password));
  // }, [new_password]);

  function PasswordUpdate(e) {
    e.preventDefault();
    setBtnLoading(true)
    const payload = {
      old_password: old_password,
      new_password: new_password,
      confirm_new_password: confirm_new_password,
    }

    axiosClient.put('/profile/password', payload)
      .then((response) => {
        setBtnLoading(false)
        // response.json({ message: 'Request received!', response })
        setCurrentPassword('')
        setNewPassword('')
        setConfirmPassword('')
        toast.success(response.data.message, {
          position: "bottom-right",
          autoClose: 1500,
          theme: "colored",
        });
        console.log('response=',response)
      }).catch(err => {
        setBtnLoading(false)
        var firstErrorMessage = '';
        console.log('errors=',err)
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          console.log('errors=',errors)
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });
  }
  return (
    <>
      <div className="pageTitle">
        <div className="">
          <h1 className="page-heading">Profile</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Update Password
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <form>
        <div class="card">
          <div class="card-body">
            <h6 class=" mb-3 text-primary">Update Password</h6>
            <div class="row">
              <div class="col-xl-6 col-lg-6">
                <div class="form-group mb-3">
                  <label for="old_password">Old Password</label>
                  <div className="innerInputIcon">
                    <input
                      type={oldPasswordType?("text"):("password")}
                      className="form-control"
                      id="old_password"
                      name="old_password"
                      placeholder='minimum 8 digit password'
                      value={old_password}
                      onChange={e => setCurrentPassword(e.target.value)}
                      // onChange={handleChange}
                    />
                    <div className="innerInputIcons">
                        {
                          oldPasswordType?
                          (
                            <span onClick={() => {setOldPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                          ):(
                            <span onClick={() => {setOldPasswordType(true)}} className="eye"><Icon.Eye /></span>
                          )
                        }
                      </div>
                  </div>
                </div>
              </div>
              <div class="row">
              </div>
              <div class="col-xl-6 col-lg-6">
                <div class="form-group mb-3">
                  <label for="new_password">New Password</label>
                  <div className="innerInputIcon">
                    <input
                      type={newPasswordType?("text"):("password")}
                      className="form-control"
                      id="new_password"
                      name="new_password"
                      placeholder='minimum 8 digit password'
                      value={new_password}
                      onChange={e => setNewPassword(e.target.value)}
                    />
                    <div className="innerInputIcons">
                      {
                        newPasswordType?
                        (
                          <span onClick={() => {setNewPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                        ):(
                          <span onClick={() => {setNewPasswordType(true)}} className="eye"><Icon.Eye /></span>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6">
                <div class="form-group mb-3">
                  <label for="password_confirmation">Confirm Password</label>
                  <div className="innerInputIcon">
                    <input
                      type={confirmPasswordType?("text"):("password")}
                      class="form-control"
                      id="email_confirmation"
                      name="email_confirmation"
                      placeholder='Must be same as new password'
                      value={confirm_new_password}
                      onChange={e => setConfirmPassword(e.target.value)}
                    />
                    <div className="innerInputIcons">
                      {
                        confirmPasswordType?
                        (
                          <span onClick={() => {setConfirmPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                        ):(
                          <span onClick={() => {setConfirmPasswordType(true)}} className="eye"><Icon.Eye /></span>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div class="row gutters">
              <div class="col-xl-12 col-lg-12">
                <div class="col text-right">
                  {/* <button
                          type="button"
                          id="submit"
                          name="submit"
                          class="btn btn-secondary"
                        >
                          Cancel
                        </button> */}
                  {/* <button
                    type="button"
                    id="submit"
                    name="submit"
                    class="btn btn-primary"
                    onClick={PasswordUpdate}
                  >
                    Update Password
                  </button> */}
                  <button type="submit" className="btn btn-primary btn-block" onClick={PasswordUpdate} disabled={btnLoading}>
                    {
                      btnLoading ?
                        (
                          <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                        ) : (
                          <>Update Password</>
                        )
                    }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      {/* 
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
        <Form.Control
          placeholder="name"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Email</InputGroup.Text>
        <Form.Control
          placeholder="Username"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup>

      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Address</InputGroup.Text>
        <Form.Control
          placeholder="Username"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup>

      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Stripe Id</InputGroup.Text>
        <Form.Control
          placeholder="Username"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup> */}
    </>
  )
}

export default UpdatePassword